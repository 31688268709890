import { __awaiter, __generator } from "tslib";
import Experiments from '@wix/wix-experiments';
export var fetchExperiments = function () { return __awaiter(void 0, void 0, Promise, function () {
    var experiments;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                experiments = new Experiments({
                    scope: 'site-search',
                });
                return [4 /*yield*/, experiments.ready()];
            case 1:
                _a.sent();
                return [2 /*return*/, experiments];
        }
    });
}); };
export var concurrentEditingEnabled = function (experiments) {
    return experiments.enabled('specs.siteSearch.ConcurrentEditing');
};
export var isWrappingWithAppWidgetEnabled = function (experiments) {
    return experiments.enabled('specs.siteSearch.ShouldWrapWithAppWidget');
};
