import { __awaiter, __generator } from "tslib";
import * as Sentry from '@sentry/browser';
import { getComponentsByType, } from '@wix/platform-editor-helpers';
import { setDemoSuggestions } from './setDemoSuggestions';
import { getAppWidgetWrappedSearchBoxDefinition, getSearchBoxComponentDefinition, } from './componentDefinitions';
import { ComponentType } from './types';
import { addBreadcrumb } from './sentry';
import { isWrappingWithAppWidgetEnabled } from './experiments';
var SEARCH_BOX_SIZE = {
    width: 305,
    height: 45,
};
export function getAllSearchBoxes(editor) {
    return __awaiter(this, void 0, Promise, function () {
        var ex_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    addBreadcrumb('getAllSearchBoxes: start');
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, getComponentsByType(editor, ComponentType.SearchBox)];
                case 2: return [2 /*return*/, _a.sent()];
                case 3:
                    ex_1 = _a.sent();
                    addBreadcrumb('getAllSearchBoxes() failed');
                    return [3 /*break*/, 5];
                case 4:
                    addBreadcrumb('getAllSearchBoxes: finish');
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/, []];
            }
        });
    });
}
export function isSearchBox(editor, componentRef) {
    return __awaiter(this, void 0, Promise, function () {
        var componentType, ex_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    addBreadcrumb('isSearchBox: start');
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, editor.SDK.components.getType(editor.token, {
                            componentRef: componentRef,
                        })];
                case 2:
                    componentType = _a.sent();
                    return [2 /*return*/, componentType === ComponentType.SearchBox];
                case 3:
                    ex_2 = _a.sent();
                    addBreadcrumb('isSearchBox() failed');
                    return [2 /*return*/, false];
                case 4:
                    addBreadcrumb('isSearchBox: finish');
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    });
}
export function addSearchBox(editor) {
    return __awaiter(this, void 0, Promise, function () {
        var headerRef, headerLayout, searchBoxLayout, getSearchBoxDefinition, ex_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    addBreadcrumb('addSearchBox: start');
                    return [4 /*yield*/, editor.SDK.siteSegments.getHeader()];
                case 1:
                    headerRef = _a.sent();
                    return [4 /*yield*/, editor.SDK.components.layout.get(editor.token, {
                            componentRef: headerRef,
                        })];
                case 2:
                    headerLayout = _a.sent();
                    searchBoxLayout = {
                        x: headerLayout.bounding.width - SEARCH_BOX_SIZE.width,
                        y: headerLayout.bounding.height > SEARCH_BOX_SIZE.height
                            ? Math.round(headerLayout.bounding.height / 2 - SEARCH_BOX_SIZE.height / 2)
                            : 0,
                        width: SEARCH_BOX_SIZE.width,
                        height: SEARCH_BOX_SIZE.height,
                    };
                    getSearchBoxDefinition = isWrappingWithAppWidgetEnabled(editor.experiments)
                        ? getAppWidgetWrappedSearchBoxDefinition
                        : getSearchBoxComponentDefinition;
                    _a.label = 3;
                case 3:
                    _a.trys.push([3, 5, 6, 7]);
                    return [4 /*yield*/, editor.SDK.components.add(editor.token, {
                            pageRef: headerRef,
                            componentDefinition: getSearchBoxDefinition({
                                appDefinitionId: editor.appDefinitionId,
                                layout: searchBoxLayout,
                            }),
                        })];
                case 4: return [2 /*return*/, _a.sent()];
                case 5:
                    ex_3 = _a.sent();
                    addBreadcrumb('addSearchBox: failed');
                    Sentry.captureException(ex_3);
                    return [3 /*break*/, 7];
                case 6:
                    addBreadcrumb('addSearchBox: finish');
                    return [7 /*endfinally*/];
                case 7: return [2 /*return*/];
            }
        });
    });
}
export function initSearchBoxSuggestions(editor, componentRef) {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    addBreadcrumb('initSearchBoxSuggestions: start');
                    return [4 /*yield*/, editor.SDK.components.data.get(editor.token, {
                            componentRef: componentRef,
                        })];
                case 1:
                    data = _a.sent();
                    if (!((data === null || data === void 0 ? void 0 : data.suggestionsEnabled) === undefined)) return [3 /*break*/, 3];
                    return [4 /*yield*/, editor.SDK.components.data.update(editor.token, {
                            componentRef: componentRef,
                            data: {
                                suggestionsEnabled: false,
                            },
                        })];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3: return [4 /*yield*/, setDemoSuggestions(editor, componentRef)];
                case 4:
                    _a.sent();
                    addBreadcrumb('initSearchBoxSuggestions: finish');
                    return [2 /*return*/];
            }
        });
    });
}
